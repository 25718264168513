<template>
    <div id="collectListPage">
      <!-- 如果没有数据的情况下，展示空图 -->
      <Empty
        v-if="detail.totalCount === 0"
        tip="お気に入りはありません"
      ></Empty>
      <van-list
        :finished="detail.finished"
        v-model="detail.loading"
        loading-text="ローディング..."
        @load="onLoad"
        :offset="30"
      >
        <van-row class="list" type="flex">

          <template v-for="item in detail.list">
            <van-col
              :key="item.id"
              :span="span"
              v-if="item.state===1"
            >
              <router-link
                :to="{name: 'Detail',query: { id: item.id } }"
                class="list-item"
              >
                <ListItem2 :detail="item">
                  <template slot="right">
                    <button type="button" class="btn" @click.prevent="onDelete(item)">お気に入り外す</button>
                  </template>
                </ListItem2>
              </router-link>
            </van-col>
          </template>

          <div class="title" v-if="invalidDetail.length">
            <div class="left">無効な動画</div>
            <div class="right" @click="clear"><i class="icon icon-clear"></i><span class="name">無効な動画を削除 </span></div>
          </div>

          <template v-for="item in invalidDetail">
            <van-col
              :key="item.id"
              :span="span"
              v-if="item.state === 2"
              class="grey"
              @click="$utils.toast('該当動画が無効になりました')"
            >
              <div
                :to="{name: 'Detail',query: { id: item.id } }"
                class="list-item"
              >
                <ListItem2 :detail="item">
                  <!-- <template slot="right">
                    <button type="button" class="btn" @click.prevent="onDelete(item)">外す</button>
                  </template> -->
                </ListItem2>
              </div>
            </van-col>
          </template>
        </van-row>
      </van-list>
    </div>
</template>

<script>
import { Empty, ListItem2 } from '@/components';
import { mapGetters } from 'vuex';
export default {
  name: 'CollectList',
  components: {
    Empty,
    ListItem2
  },
  data () {
    return {
      span: 24,
      collectVideo: [],
      selectId: '',
      detail: {
        pageNo: 1,
        loading: false,
        finished: false,
        totalCount: '',
        list: [],
        pageSize: 20
      }
    };
  },
  computed: {
    ...mapGetters(['htmlWidth']),

    /* 失效列表视频 */
    invalidDetail () {
      return this.detail.list.filter((item) => item.state === 2);
    }
  },
  created () {
  },
  mounted () {
    this.onResize();
  },
  watch: {
    htmlWidth () {
      this.onResize();
    }
  },
  methods: {

    /* 页面加载事件 */
    onLoad () {
      let { detail } = this;
      let { pageNo, pageSize } = detail;
      this.$http.get('mine/collectVideo', {
        params: {
          pageNo,
          pageSize
        }
      }).then((res) => {
        let { isHasNext, nextPage, result, totalCount } = res.data || {};
        detail.list.push(...result);
        detail.finished = !isHasNext;
        detail.pageNo = nextPage;
        detail.totalCount = totalCount;
        detail.loading = false;
        this.detail = detail;
      });
    },

    /* 删除收藏的视频 */
    onDelete (item) {
      const { $utils, $http } = this;
      console.log(item.id);
      $http.get('video/collectVideo', {
        params: {
          videoId: item.id
        }
      }).then((res) => {
        $utils.successToast({
          message: '削除成功'
        });
        this.init();
        // this.onLoad();
      });

    },

    /* 清除失效的视频 */
    clear () {
      this.$http.get('mine/clear/collect').then((res) => {
        this.init();
      });
    },

    /* 初始化列表信息数据 */
    init () {
      this.detail = {
        pageNo: 1,
        loading: false,
        finished: false,
        totalCount: '',
        list: [],
        pageSize: 20
      };
    },

    onResize () {
      const { htmlWidth } = this;
      this.span = 24;
      if (htmlWidth > 1200) {
        this.span = 8;
      } else if (htmlWidth > 992) {
        this.span = 12;
      }
    }


  }
};
</script>

<style scoped lang="less">
  #collectListPage {
    .list {
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 28px;
        margin: 62px 0 23px;
        font-size: 26px;

        .icon {
          width: 32px;
          height: 32px;
          vertical-align: middle;

          &.icon-clear {
            background-image: url("~@/assets/images/icon_clear_01.png");
          }
        }
      }

      .list-item {
        position: relative;
        width: 100%;
        padding: 28px;

        &::after {
          position: absolute;
          bottom: 0;
          left: 28px;
          width: calc(100% - 56px);
          height: 1px;
          content: '';
          background-color: rgba(255, 255, 255, 0.05);
        }

        .btn {
          height: 48px;
          font-size: 18px;
          border: 2px solid #fff;
          border-radius: 8px;
          opacity: 0.6;
        }
      }
    }
  }

  .grey {
    color: #a8a8a8 !important;
    opacity: 0.6;
  }
</style>